"use client";

import { LogoClick } from "@/icons";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { AnimatedHamburgerButton } from "../animatedHamburguerButton";

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isActive = (href: string) => pathname === href.split("#")[0];

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const handleNavigation = (href: string) => async (e: React.MouseEvent) => {
    e.preventDefault();
    if (isMobile) toggleMenu();

    if (href.startsWith("/#")) {
      const [targetPath, hash] = href.split("#");
      if (pathname === targetPath) {
        document.getElementById(hash)?.scrollIntoView({ behavior: "smooth" });
      } else {
        await router.push(targetPath);
        setTimeout(
          () =>
            document
              .getElementById(hash)
              ?.scrollIntoView({ behavior: "smooth" }),
          100
        );
      }
    } else {
      router.push(href);
    }
  };

  useEffect(() => {
    document.body.classList.toggle("overflow-hidden", isMenuOpen);
    return () => document.body.classList.remove("overflow-hidden");
  }, [isMenuOpen]);

  const navItems = [
    { label: "Como funciona", href: "/#como-funciona" },
    { label: "Galeria", href: "/galeria" },
    { label: "Depoimentos", href: "/#depoimentos" },
    { label: "Benefícios", href: "/#beneficios" },
    { label: "Sobre nós", href: "/sobre-nos" },
    {
      label: "Médicos Click",
      href: "/seja-parceiro",
    },
    { label: "FAQ", href: "/duvidas-frequentes" },
  ];

  return (
    <header className="fixed left-0 top-0 z-40 flex w-full justify-between bg-white p-4 md:px-10">
      <Link
        href="https://clickcannabis.com"
        aria-label="Ir para a página inicial da ClickCannabis"
      >
        <LogoClick />
      </Link>

      <div className="flex items-center">
        <AnimatedHamburgerButton isOpen={isMenuOpen} toggle={toggleMenu} />
        <nav className="ml-6 hidden md:block">
          <ul className="flex space-x-6">
            {navItems.map(({ label, href }) => (
              <li key={href}>
                <a
                  href={href}
                  onClick={handleNavigation(href)}
                  className={`${
                    isActive(href) ? "text-brand-dark" : "text-gray-700"
                  } hover:text-brand-dark`}
                >
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      {isMenuOpen && (
        <div
          className="fixed inset-0 z-40 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out"
          onClick={toggleMenu}
          onKeyUp={() => {}}
        >
          <div
            className="fixed right-0 top-0 z-50 h-full w-64 translate-x-0 transform bg-white p-6 shadow-lg transition-transform duration-300 ease-in-out"
            onClick={(e) => e.stopPropagation()}
            onKeyUp={() => {}}
          >
            <div className="mb-6 flex justify-end">
              <AnimatedHamburgerButton
                isOpen={isMenuOpen}
                toggle={toggleMenu}
              />
            </div>
            <nav>
              <ul className="space-y-4">
                {navItems.map(({ label, href }) => (
                  <li key={href}>
                    <a
                      href={href}
                      onClick={handleNavigation(href)}
                      className="text-gray-700 hover:text-brand-dark"
                    >
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      )}
    </header>
  );
};
